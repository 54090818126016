import React from 'react'
import styled from 'styled-components'
import { FaArrowRight, FaArrowDown } from 'react-icons/fa'
import Copytext from '../copytext'
import Arrow from '../arrow'

const TopicWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
`

const HeadlineTopic = styled.h3`
  color: ${props => (props.inverted ? '#d3a04e' : '#323232')};
  font-family: 'Open Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

class Topic extends React.Component {
  state = { open: false }

  handleToggle = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  render() {
    const { headline, text } = this.props
    return (
      <TopicWrapper>
        <HeadlineTopic inverted={this.state.open} onClick={this.handleToggle}>
          {headline}
          <Arrow inverted={this.state.open}>
            {this.state.open === true ? <FaArrowDown /> : <FaArrowRight />}
          </Arrow>
        </HeadlineTopic>
        {this.state.open && (
          <Copytext style={{ textAlign: 'left' }}>{text}</Copytext>
        )}
      </TopicWrapper>
    )
  }
}

export default Topic
