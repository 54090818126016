import React from 'react'
import styled from 'styled-components'
import HeadlineTwo from '../headlinetwo'
import HeadlineExtra from '../headlineextra'
import Underline from '../underline'
import Copytext from '../copytext'
import InclinedRocket from '../../images/inclined-rocket.png'
import PieCart from '../../images/pie-chart.png'
import Settings from '../../images/settings.png'
import PiggiBank from '../../images/piggy-bank.png'
import Investment from '../../images/investment.png'
import InvestmentModel from '../../images/investment-model.png'
import OpenBook from '../../images/open-book.png'
import Research from '../../images/research.png'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const OuterBox = styled.div`
  position: relative;
  width: 299px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

const InnerBox = styled.div`
  width: 270px;
  height: 167px;
  background-color: ${props => (props.inverted ? '#d3a04e' : '#fff')};
`

const IconBox = styled.div`
  position: absolute;
  top: 0;
  width: 65px;
  height: 56px;
  border: 1px solid #d3a04e;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ClientBranch = styled.div`
  color: ${props => (props.inverted ? '#fff' : '#323232')};
  font-size: 20px;
  font-weight: 500;
  line-height: 45px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Roboto';
  margin-top: 40px;
`

const ClientBranchCopy = styled.div`
  color: ${props => (props.inverted ? '#fff' : '#898989')};
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
`

const Image = styled.img`
  margin: 0;
`

export default function Clients() {
  return (
    <div
      style={{ backgroundColor: '#f7f7f7', paddingTop: 40, paddingBottom: 40 }}
    >
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1280,
          padding: '1.45rem 1.0875rem 0',
        }}
      >
        <HeadlineTwo>
          <HeadlineExtra>Unsere</HeadlineExtra> Branchen | Mandanten
        </HeadlineTwo>
        <Underline />
        <Copytext>
          Unser Kundenportfolio umfasst alle Branchen egal ob Handwerk oder
          Managementetage
        </Copytext>
        <BoxWrapper>
          <OuterBox>
            <IconBox>
              <Image src={InclinedRocket} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Elektronik & Software</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={PieCart} alt="" />
            </IconBox>
            <InnerBox inverted>
              <ClientBranch inverted>Bau und Immobilien</ClientBranch>
              <ClientBranchCopy inverted>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={Settings} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Handel & Konsumgüter</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={PiggiBank} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Finanzdienstleistungen</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={Investment} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Energiewirtschaft</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={InvestmentModel} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Family Office</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={OpenBook} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Gastronomie</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
          <OuterBox>
            <IconBox>
              <Image src={Research} alt="" />
            </IconBox>
            <InnerBox>
              <ClientBranch>Gesundheitswesen</ClientBranch>
              <ClientBranchCopy>______________</ClientBranchCopy>
            </InnerBox>
          </OuterBox>
        </BoxWrapper>
      </div>
    </div>
  )
}
