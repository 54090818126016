import React from 'react'
import styled from 'styled-components'
import HeadlineTwo from '../headlinetwo'
import HeadlineExtra from '../headlineextra'
import Underline from '../underline'
import Copytext from '../copytext'
import Marco from '../../images/marco.jpg'
import Topic from './topic'

const Wrapper = styled.div`
  display: flex;
  margin: 0;
`

const LeftCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const RightCol = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    display: none;
  }
`

const Image = styled.img`
  margin: 0;
`

export default function QandA() {
  return (
    <div style={{ backgroundColor: '#f7f7f7', paddingTop: 50 }}>
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1280,
          padding: '1.45rem 1.0875rem 0',
        }}
      >
        <HeadlineTwo>
          <HeadlineExtra>Fragen</HeadlineExtra> die Sie beschäftigen
        </HeadlineTwo>
        <Underline />
        <Copytext>
          Fall Sie diese Fragen brennens beschäftigen sind Sie genau richtig bei
          uns. Wir werden Ihnen auf jeden Fall helfen können.
        </Copytext>
        <Wrapper>
          <LeftCol>
            <Topic
              headline="Was gibt es zu beachten, wenn ich einen sogenannten Minijobber einstelle?"
              text="Im Bereich der Minijobber sind einige Besonderheiten, insbesondere im Zusammenhang mit der Rentenversicherung, zu beachten. Aktuelle Informationen finden Sie auf der Homepage der Minijobzentrale."
            />
            <Topic
              headline="Können Sie uns auch in der Erbschaftssteuer beraten ?"
              text="Selbstverständlich übernehmen wir die Erstellung der Erklärungen für Ihre Erbschaft oder Ihre Schenkung mit den dazugehörigen Bewertungen für sämtliche Vermögensgüter, wie z.B. Immobilien oder Grund/Boden."
            />
            <Topic
              headline="Welche Rechtsformen betreuen Sie?"
              text="Wir betreuen alle Rechtsformen; also Einzelunternehmen, sowie Personengesellschaften und Kapitalgesellschaften."
            />
            <Topic
              headline="Können Sie unsere Lohnbuchhaltung übernehmen ?"
              text="Natürlich können wir das! Wir erstellen neben den monatlichen Lohnabrechnungen auch die dazugehörigen Meldungen an das Finanzamt und die Krankenkassen sowie die Meldungen bei Krankheit/Mutterschaft und die Jahreslohnsteuerbescheinigungen."
            />
          </LeftCol>
          <RightCol>
            <Image src={Marco} style={{ width: 400 }} />
          </RightCol>
        </Wrapper>
      </div>
    </div>
  )
}
