import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Infobox from '../infobox'

import { FaChessRook, FaCubes, FaUserFriends } from 'react-icons/fa'

const Infobarwrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #ccc;
`

const LinkFull = styled(Link)`
  flex: 1;
  text-decoration: none;
`

function Infobar() {
  return (
    <Infobarwrapper>
      <LinkFull to="/about-us">
        <Infobox
          headline="Unsere Kanzlei"
          bg="#d3a04d"
          icon={<FaChessRook />}
          subline="Steuerberatung seit 2006"
        />
      </LinkFull>
      <LinkFull to="/leistungen">
        <Infobox
          headline="Unsere Leistungen"
          bg="#000"
          icon={<FaCubes />}
          subline="Alles auf einen Blick"
        />
      </LinkFull>
      <LinkFull to="/branchen">
        <Infobox
          headline="Unsere Mandanten"
          bg="#333333"
          icon={<FaUserFriends />}
          subline="Diese Branchen betreuen wir"
        />
      </LinkFull>
    </Infobarwrapper>
  )
}

export default Infobar
