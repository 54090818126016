import React from 'react'

import Layout from '../components/layout'
import Branding from '../components/branding'
import Infobar from '../components/infobar'
import Mario2 from '../images/mario2.jpg'
import ImageText from '../components/imagetext'
import Clients from '../components/clients'
import Testimonials from '../components/testimonials'
import ConsultingAppointment from '../components/consultingappointment'
import QandA from '../components/qanda'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

/*

<div style={{ backgroundColor: '#f7f7f7', paddingTop: 50 }}>
  <div
    style={{
      margin: '0 auto',
      maxWidth: 1280,
      padding: '1.45rem 1.0875rem 0',
    }}
  >
    <HeadlineTwo>
      <HeadlineExtra>Fragen</HeadlineExtra> die Sie beschäftigen
    </HeadlineTwo>
    <Underline />
    <Copytext>
      Fall Sie diese Fragen brennens beschäftigen sind Sie genau richtig
      bei uns. Wir werden Ihnen auf jeden Fall helfen können.
    </Copytext>
    <Wrapper>
      <LeftCol>
        <Topic>
          <HeadlineTopic>
            Wann muss die Einkommensteuer abgegeben werden ?
            <Arrow>
              {this.state.open === true ? (
                <FaArrowDown />
              ) : (
                <FaArrowRight />
              )}
            </Arrow>
          </HeadlineTopic>
        </Topic>
        <Topic>
          <HeadlineTopic inverted>
            Können Sie uns auch in der Erbschaftssteuer beraten ?
            <Arrow inverted>
              <FaArrowDown />
            </Arrow>
          </HeadlineTopic>
          <Copytext style={{ textAlign: 'left' }}>
            A phasellus duis, purus leo congue eget convallis in, integer
            pelltesququam quis pede mauris, nisl ipsum at, in ipsum vopat
            suscipit risus cursus Nam
          </Copytext>
        </Topic>
        <Topic>
          <HeadlineTopic>
            Welche Rechtsformen betreuen Sie alle ?
          </HeadlineTopic>
        </Topic>
        <Topic>
          <HeadlineTopic>
            Können Sie unsere Lohnbuchhaltung übernehmen ?
          </HeadlineTopic>
        </Topic>
      </LeftCol>
      <RightCol>
        <Image src={Marco} style={{ width: 400 }} />
      </RightCol>
    </Wrapper>
  </div>
</div>

*/

const IndexPage = () => (
  <Layout>
    <div>
      <Branding />
      <Infobar />
      <ImageText
        bg={Mario2}
        subline="Herzlich willkommen bei der"
        headline="Steuerberatung Mario Ruberto"
      >
        <p>
          <strong>Kanzlei</strong>
          <br />
          Wie findet man den richtigen Steuerberater? Diese Frage ist etwa so
          alt wie die Steuergesetzgebung selbst. Und diese ist in ihrer ganzen
          Komplexität selbst für Juristen der wohl am schwierigsten zu
          durchschauende Gesetzestext in unserem Lande.
          <br />
          <br />
          Ein guter Steuerberater sollte sich deshalb vor allem eines für Sie
          nehmen: Zeit.
          <br />
          <br />
          Denn ganz gleich, ob es um eine anspruchsvolle
          Einkommensteuererklärung unter Berücksichtigung von Einkünften aus
          Vermietung und Verpachtung geht, um die steuerlichen Auswirkungen
          einer Erbschaft bzw. Schenkung oder um den vermeintlich „einfachen“,
          freiwilligen Lohnsteuerjahresausgleich für eine Privatperson der
          Lohnsteuerklasse 1 bzw. 4/4 bei Verheirateten. Ob es sich um die
          steuerlichen Gestaltungsmöglichkeiten rund um die Künstlersozialkasse
          KSK bei Künstlern, Sportlern, Freelancern, Journalisten und
          Influencern handelt oder ob Ihnen die Optimierung von Finanzierungs-
          und Investitionsfragen für Ihr Unternehmen am Herzen liegt: Ein gutes
          Mandat ist stets nur dann ein gutes Mandat, wenn Ihr Steuerberater
          auch den Mensch bzw. das Unternehmen in all seinen Facetten und Zielen
          versteht, das hinter diesem Mandat steht. An diesem Anspruch dürfen
          Sie jede Leistung, die wir für Sie erbringen, messen.
          Selbstverständlich vertritt unsere Kanzlei auch Ihre Interessen in
          allen Steuerangelegenheiten gegenüber sämtlichen zuständigen Behörden
          und Gerichten.
          <br />
          <br />
          Ihr Mario Ruberto, Steuerberater München.
        </p>
      </ImageText>
      <Clients />
      <Testimonials />
      <ConsultingAppointment
        title="Sie wünschen ein Beratungstermin - nichts einfacher als das..."
        cta="Termin vereinbaren"
        color="#fff"
        bgcolor="#d3a04e"
      />
      <QandA />
      <Footer />
      <SubFooter />
    </div>
  </Layout>
)

export default IndexPage
