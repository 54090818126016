import React, { Component } from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import styled from 'styled-components'

import Slide from '../../images/sliderplaceholder.jpg'
import Slide2 from '../../images/sliderplaceholder2.jpg'
import Slide3 from '../../images/sliderplaceholder3.jpg'

const Slideone = styled.div`
  margin: 0 auto;
  height: 100%;
`

//padding-bottom: 736/1920 * 100 %
const SliderWrapper = styled.div`
  height: 0;
  padding-bottom: 39%;
`

function Branding() {
    const settings = {
      autoplay: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }
    return (
      <SliderWrapper>
        <Slider {...settings}>
          <Link to="/kontakt">
            <Slideone>
              <img src={Slide} alt="" style={{ margin: 0 }} />
            </Slideone>
          </Link>
          <Link to="/kontakt">
            <Slideone>
              <img src={Slide2} alt="" style={{ margin: 0 }} />
            </Slideone>
          </Link>
        </Slider>
      </SliderWrapper>
    )
}

export default Branding
