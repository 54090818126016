import React from 'react'
import styled from 'styled-components'

const Infoboxextend = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 268px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bg};

  @media (max-width: 1000px) {
    min-width: 400px;
  }
`
const Headline = styled.h1`
  font-size: 1.7rem;
  color: white;
  margin-bottom: 10px;
`
const Subline = styled.p`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: white;
`

const Icon = styled.div`
  padding: 20px;
  border-radius: 50% 50%;
  border: 3px solid #fff;
  font-size: 2rem;
  color: white;
  margin-bottom: 10px;
`

const Infobox = props => (
  <div style={{ display: 'flex', flex: 1 }}>
    <Infoboxextend bg={props.bg}>
      <Icon>{props.icon}</Icon>
      <Headline>{props.headline}</Headline>
      <Subline>{props.subline}</Subline>
    </Infoboxextend>
  </div>
)

export default Infobox
