import styled from 'styled-components'

const Arrow = styled.div`
  color: ${props => (props.inverted ? '#d3a04e' : '#323232')};
  font-size: 22px;
  font-weight: 400;
  line-height: 24px;
`

export default Arrow
